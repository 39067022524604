import { render } from "./evaluate.vue?vue&type=template&id=3e142760&scoped=true"
import script from "./evaluate.vue?vue&type=script&lang=js"
export * from "./evaluate.vue?vue&type=script&lang=js"

import "./evaluate.vue?vue&type=style&index=0&id=3e142760&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-3e142760"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3e142760"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3e142760', script)) {
    api.reload('3e142760', script)
  }
  
  module.hot.accept("./evaluate.vue?vue&type=template&id=3e142760&scoped=true", () => {
    api.rerender('3e142760', render)
  })

}

script.__file = "src/views/shop/detail/evaluate/evaluate.vue"

export default script