<template>
   <div class="wrapper">
<!--    商城评价页面 -->
     <headBack>
       <template v-slot:title>
         <div class='title'>
           评价({{evaluate.count}})
         </div>
       </template>
     </headBack>
     <div class='header_cont'></div>
     <div class="main">

       <div class="p_detail" v-for="(item,index) in evaluate.pageData">
         <div class="user_box">
           <img class="tx" :src="item.userPicUrl" alt="" />
           <div class="u_name">
             <div>{{item.nickName}}</div>
             <div>{{item.evaluateDateDesc}}</div>
           </div>
           <div class="pf">
             <van-rate
                 v-model="item.star"
                 :size="16"
                 color="#00c291"
                 void-icon="star"
                 void-color="#eee"
             />
           </div>
         </div>
         <div class="info">{{item.content}}</div>
         <div class="imgs">
             <img v-for="(e,i) in item.contentUrlList" :src="e.picUrl" alt="">
         </div>
       </div>
     </div>
   </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'
import loginApi from '@axios/login'
import shopApi from '@axios/shop'

export default {
  name: "evaluate",
  setup(){
    const route = useRoute()
    const evaluate = ref('')
    const getEvaluateInfo = () => { //评价
      const params = {
        goodsId: route.query.goodsId,
        orderId:'',
        pageNum:1,
        pageSize:20,
      }
      shopApi.getEvaluateSelectForGoods(params).then((res)=> {

        evaluate.value =res.data;
        console.log(res)
      })
    }
    getEvaluateInfo();
    return {
      evaluate
    }
  }
}
</script>

<style lang="scss" scoped>
   .wrapper{
      width: 100%;
      min-height: 100%;
     background: #efeff4;


    .main{
       box-sizing: border-box;
      padding:20px;
      .p_detail{
        padding:20px;
        font-size: 24px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-bottom: 20px;
        .user_box{
          display: flex;
          justify-content: space-between;
          .tx{
            width: 70px;
            height: 70px;
            margin-right: 20px;
            border-radius: 50%;
          }
          .u_name{
            flex-grow: 1;
          }
        }
        .info{
          margin-top: 10px;
          font-size: 24px;
        }
        .imgs{
          margin-top: 10px;
          display: flex;
           img{
             width: 120px;
             height: 120px;
             margin-right: 16px;
           }
        }
      }
    }
   }
</style>
