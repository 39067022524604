
import homeApi from '@axios/home'
export function setWxShareDate(title,imgUrl,url,subTxt){
    let formData = new FormData();
    formData.append('data', JSON.stringify({
         js_url:url? url : location.href
    }));
     const urls = url ? url : location.href;
     const subTxts = subTxt ? subTxt : '快天使医护到家上门服务平台'
     // const urls = encodeURIComponent(location.href);
     const img_url = encodeURIComponent (imgUrl);
    formData.append('encryption', false)

      homeApi.getAppId(formData).then( (res) =>{
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: res.data.appId, // 必填，公众号的唯一标识
                timestamp:Number(res.data.timestamp), //必填，生成签名的时间戳
                nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                signature: res.data.signature,// 必填，签名
                jsApiList: [
                    'updateAppMessageShareData',
                    'updateTimelineShareData',
                    'onMenuShareAppMessage',
                    'onMenuShareQQ',
                    'onMenuShareWeibo',
                ] // 必填，需要使用的JS接口列表
            });
            wx.ready(function () {   //需在用户可能点击分享按钮前就先调用

                wx.updateAppMessageShareData({
                    title: title, // 分享标题
                    desc: subTxts, // 分享描述
                    link: urls, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: imgUrl, // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })

                wx.updateTimelineShareData({//分享朋友圈  注  link 与imgUrl 安卓与iOS 处理不一样 目前只满足了IOS
                    title: title, // 分享标题
                    link: urls, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: imgUrl, // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })
            });
        }).catch((err)=>{
            console.log(err)
        })

}
