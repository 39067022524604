import axios from './index'

class shop {
    //首页
    static getMallAnnouncements(params) { // 首页公告
        return axios.post(`mall/getMallAnnouncements`,params)
    }
    static getMallBanner(params) { // 轮播图
        return axios.post(`mall/getMallBanner`,params)
    }

    //商品模块
    static getTypeList(params) { // 商品分类
        return axios.post(`mall/goods/typeList`,params)
    }
    static getMallList(params) { // 商品列表
        return axios.post(`mall/goods/list`,params)
    }
    static getMallSearch(params) { // 商品搜索
        return axios.post(`mall/goods/search`,params)
    }
    static getGoodsRecommend(params) { // 特定商品更多推荐
        return axios.post(`mall/goods/recommend`,params)
    }
    static getGoodsYouLike(params) { // 特定用户商品更多推荐
        return axios.post(`mall/goods/youlike`,params)
    }
      //商品详情
    static getGoodsDetail(params) { // 特定用户商品更多推荐
        return axios.post(`mall/goods/detail`,params)
    }
    static getGoodsQualifications(params) { // 商品相关资质保证接口
        return axios.post(`mall/goods/qualifications`,params)
    }
    static getlogistics(params) { // 商品物流信息相关接口
        return axios.post(`mall/goods/logistics`,params)
    }
    static getInventoryInfos(params) { // 商品库存信息相关接口
        return axios.post(`mall/goods/inventoryInfos`,params)
    }
    static getEvaluateSelectForGoods(params) { // 商品评价相关接口
        return axios.post(`mall/order/evaluateSelectForGoods`,params)
    }

    //订单模块
    static getOrderList(params) { // 我的订单
        return axios.post(`mall/order/list`,params);
    }
    static getOrderDetail(params) { // 订单详情
        return axios.post(`mall/order/detail`,params);
    }
    static getFreightPrice(params) { // 获取运费
        return axios.post(`mall/order/freightPrice`,params);
    }
    static getOrderSubmit(params) { // 创建订单
        return axios.post(`mall/order/submit`,params);
    }
    static getOrderCancel(params) { // 取消订单
        return axios.post(`mall/order/cancel`,params);
    }
    static getConfirmReceip(params) { // 确认订单
        return axios.post(`mall/order/confirmReceip`,params);
    }

    static getOrderLogistics(params) { // 订单物流
        return axios.post(`mall/order/logistics`,params);
    }
    static evaluateSave(params) { // 订单评价保存接口
        return axios.post(`mall/order/evaluateSave`,params);
    }
    static evaluateSelect(params) { // 订单评价查询
        return axios.post(`mall/order/evaluateSelect`,params);
    }
    static afterSalesMode(params) { // 售后方式列表
        return axios.post(`mall/order/afterSalesMode`,params);
    }
    static afterSalesReasons(params) { // 售后原因列表
        return axios.post(`mall/order/afterSalesReasons`,params);
    }
    static afterSalesSubmit(params) { // 订单售后提交
        return axios.post(`mall/order/afterSalesSubmit`,params);
    }

    //售后
    static afterSalesList(params) { // 售后列表查询
        return axios.post(`mall/afterSales/list`,params);
    }
    static afterSalesDetail(params) { // 售后列表详情
        return axios.post(`mall/afterSale/detail`,params);
    }
    static afterSaleSaveExpressInfo(params) { // 保存快递信息
        return axios.post(`mall/afterSale/saveExpressInfo`,params);
    }
    static afterSaleCancel(params) { // 撤销售后申请
        return axios.post(`mall/afterSale/cancel`,params);
    }


    //交易模块
    //收货地址管理
    static getAddressList(params) { //收货地址列表接口
        return axios.post(`mall/address/list`,params);
    }
    static getAddressDetail(params) { //收货地址详情
        return axios.post(`mall/address/detail`,params);
    }
    static getAddressAddSave(params) { //新增收货地址
        return axios.post(`mall/address/addSave`,params);
    }
    static getAddressUpdateSave(params) { // 编辑收货地址 /mall/address/delete
        return axios.post(`mall/address/updateSave`,params);
    }
    static getAddressDelete(params) { // 删除收货地址 /mall/address/delete
        return axios.post(`mall/address/delete`,params);
    }

    //购物车模块
    static getCartInfos(params) { // 获取购物车
        return axios.post(`mall/shoppingCart/infos`,params)
        // return axios.post(`http://h5.yihu365.com/NurseHomeControl.action`, params);
    }

    static setSynchronization(params) {//数据同步
        return axios.post(`mall/shoppingCart/synchronization`, params);
    }

    static setCartEmpty(params) {// 清空数据
        return axios.post(`mall/shoppingCart/empty`, params)
    }
}

export default shop;
